import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  Card,
  TextField,
  SnackbarContent,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import IntroImage from "../../images/marriage-intro-image.jpg";
import QualificationIcon from "../../components/assets/qualification-icon";
import CertificationIcon from "../../components/assets/certification-icon";
import LinkWrapper from "../../components/LinkWrapper";
import jwt from "jsonwebtoken";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3' 

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#EDF4F545",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "5%",
    textAlign: "center",
  },
  profileImage: {
    width: "100%",
    height: "500px",
    position: "relative",
    backgroundImage: `url(${IntroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "initial",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
      backgroundSize: "contain",
    },
  },
  content: {
    width: "100%",
    paddingTop: "2%",
    paddingBottom: "2%",
    textAlign: "left",
  },
  footnote: {
    color: "#80818D",
    textAlign: "center",
    paddingBottom: "2%",
    paddingTop: "5%",
  },
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: "#EDF4F573",
  },
  cardContent: {
    padding: "2% 5%",
    color: "#80818D",
    textAlign: "left",
  },
  cardTitle: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "1%",
  },
  paddedTopContainer: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5%",
    },
  },
  button: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
});
const CssTextField = withStyles({
  root: {
    "& label": {
      color: "#80818D",
    },
    "& label.Mui-focused": {
      color: "#80818D",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      color: "#181A34",
      "& fieldset": {
        borderColor: "#181A3433",
      },
      "&:hover fieldset": {
        borderColor: "#181A3433",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#181A3433",
      },
    },
  },
})(TextField);
const ColorButton = withStyles(theme => ({
  root: {
    padding: "10px 40px",
    color: "#4853BBFF",
    border: "1.8px solid #4853BBFF",
    borderRadius: "32px",
    fontWeight: "bold",
    "&:hover": {
      color: "white",
      backgroundColor: "#181A34E6",
      border: "1.5px solid #181A34E6",
    },
  },
}))(Button);
const MarriageRegistration = props => {
  const classes = props.classes;
  const [registrationValues, setRegistrationValues] = React.useState({
    hisName: "",
    hisPhone: "",
    hisEmail: "",
    hisOccupation: "",
    hisDob: "",
    herName: "",
    herPhone: "",
    herEmail: "",
    herOccupation: "",
    herDob: "",
    weddingDate: "",
  });
  const [registrationError, setRegistrationError] = React.useState(false);
  const [registrationSnackbar, setRegistrationSnackbar] = React.useState({
    open: false,
    isSuccess: true,
    message: "",
  });
  const handleRegChange = prop => event => {
    setRegistrationValues({
      ...registrationValues,
      [prop]: event.target.value,
    });
  };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const sendMessage = async event => {
    event.preventDefault();
    setRegistrationError(false);
    setRegistrationSnackbar({ ...registrationSnackbar, open: false });

    if(!executeRecaptcha) {
      setRegistrationError(true);
      setRegistrationSnackbar({
        open: true,
        isSuccess: false,
        message: "Failed to send your request. Please try again!",
      });
      return;
    }
    const captchaResult = await executeRecaptcha('MarriageRegistration');

    let body = {
      hisName: "",
      hisPhone: "",
      hisEmail: "",
      hisOccupation: "",
      hisDob: "",
      herName: "",
      herPhone: "",
      herEmail: "",
      herOccupation: "",
      herDob: "",
      weddingDate: "",
      captchaToken: "" 
    };
    body.hisName = registrationValues.hisName;
    body.hisPhone = registrationValues.hisPhone;
    body.hisEmail = registrationValues.hisEmail;
    body.hisOccupation = registrationValues.hisOccupation;
    body.hisDob = registrationValues.hisDob;
    body.herName = registrationValues.herName;
    body.herPhone = registrationValues.herPhone;
    body.herEmail = registrationValues.herEmail;
    body.herOccupation = registrationValues.herOccupation;
    body.herDob = registrationValues.herDob;
    body.weddingDate = registrationValues.weddingDate;
    body.captchaToken = captchaResult;
    let protocol = window.location.protocol;
    let url = protocol + "//" + window.location.host + "/.netlify/functions/marriage-registration";
    // let url = "http://localhost:9000/marriage-registration";
    const token = jwt.sign(body, process.env.GATSBY_FUNCTION_API_SECRET, {
      expiresIn: "180s",
    });
    fetch(url, {
      // mode: 'no-cors',
      body: JSON.stringify(body),
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(response => {
        if (response.status == 200) {
          setRegistrationError(false);
          setRegistrationSnackbar({
            open: true,
            isSuccess: true,
            message: "You request has been sent!",
          });
        } else {
          setRegistrationError(true);
          setRegistrationSnackbar({
            open: true,
            isSuccess: false,
            message: "Failed to send your request. Please try again!",
          });
        }
      })
      .catch(error => {
        setRegistrationError(true);
        setRegistrationSnackbar({
          open: true,
          isSuccess: false,
          message: "Failed to send your request. Please try again!",
        });
      });
  };
  return (
    <section id="MarriageRegistration" className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Registration
        </Typography>
        <form autoComplete="on" onSubmit={sendMessage}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="stretch"
          >
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.title}>
                His
              </Typography>
              <Grid
                container
                spacing={2}
                direction="column"
                justify="center"
                alignItems="stretch"
              >
                <Grid item xs={12}>
                  <CssTextField
                    label="Full Name"
                    placeholder="appear on the certificate"
                    fullWidth
                    value={registrationValues.hisName}
                    onChange={handleRegChange("hisName")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Email"
                    type="email"
                    placeholder="for PREPARE assessment"
                    fullWidth
                    value={registrationValues.hisEmail}
                    onChange={handleRegChange("hisEmail")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Mobile No."
                    type="tel"
                    fullWidth
                    value={registrationValues.hisPhone}
                    onChange={handleRegChange("hisPhone")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Occupation"
                    fullWidth
                    value={registrationValues.hisOccupation}
                    onChange={handleRegChange("hisOccupation")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Date of Birth"
                    type="date"
                    fullWidth
                    value={registrationValues.hisDob}
                    onChange={handleRegChange("hisDob")}
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.title}>
                Her
              </Typography>
              <Grid
                container
                spacing={2}
                direction="column"
                justify="center"
                alignItems="stretch"
              >
                <Grid item xs={12}>
                  <CssTextField
                    label="Full Name"
                    placeholder="appear on the certificate"
                    fullWidth
                    value={registrationValues.herName}
                    onChange={handleRegChange("herName")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Email"
                    type="email"
                    placeholder="for PREPARE assessment"
                    fullWidth
                    value={registrationValues.herEmail}
                    onChange={handleRegChange("herEmail")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Mobile No."
                    type="tel"
                    fullWidth
                    value={registrationValues.herPhone}
                    onChange={handleRegChange("herPhone")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Occupation"
                    fullWidth
                    value={registrationValues.herOccupation}
                    onChange={handleRegChange("herOccupation")}
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    label="Date of Birth"
                    type="date"
                    fullWidth
                    value={registrationValues.herDob}
                    onChange={handleRegChange("herDob")}
                    variant="outlined"
                    required
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.paddedTopContainer}>
            <CssTextField
              label="Wedding date (optional)"
              type="date"
              fullWidth
              value={registrationValues.weddingDate}
              onChange={handleRegChange("weddingDate")}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className={classes.paddedTopContainer}>
            <SnackbarContent
              message={registrationSnackbar.message}
              style={{
                display: registrationSnackbar.open ? "block" : "none",
                backgroundColor: registrationSnackbar.isSuccess
                  ? "#4caf50"
                  : "#f44336",
              }}
            />
          </div>
          <div className={classes.paddedTopContainer}>
            <ColorButton
              className={classes.button}
              variant="outlined"
              size="medium"
              type="submit"
            >
              Register
            </ColorButton>
          </div>
        </form>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.footnote}
        >
          <b>PERSONAL DATA PROTECTION</b>
          <p>
            By disclosing information on and submitting this form, it is deemed
            that both parties registering for the course have given consent to
            Cherng’s Listening Ear for the collecting and using of the personal
            data for the purpose of Marriage Preparation activities. Cherng’s
            Listening Ear respects personal data and confidentiality, and will
            not share such information with any persons.
          </p>
        </Typography>
      </Container>
    </section>
  );
};

export default withStyles(styles)(MarriageRegistration);
