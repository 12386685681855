import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Container, Typography, Button, Card } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import IntroImage from "../../images/marriage-intro-image.jpg";
import QualificationIcon from "../../components/assets/qualification-icon";
import CertificationIcon from "../../components/assets/certification-icon";
import LinkWrapper from "../../components/LinkWrapper";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#EDF4F545",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "5%",
    textAlign: "center",
  },
  profileImage: {
    width: "100%",
    height: "500px",
    position: "relative",
    backgroundImage: `url(${IntroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "initial",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
      backgroundSize: "contain",
    },
  },
  content: {
    width: "100%",
    paddingTop: "2%",
    paddingBottom: "2%",
    textAlign: "left",
  },
  footnote: {
    color: "#80818D",
    textAlign: "center",
    paddingBottom: "2%",
    paddingTop: "5%",
  },
  card: {
    height: "100%",
    width: "100%",
    backgroundColor: "#EDF4F566",
  },
  cardContent: {
    padding: "2% 5%",
    color: "#80818D",
    textAlign: "left",
  },
  cardTitle: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "1%",
  },
});

const MarriageCourse = props => {
  const classes = props.classes;
  return (
    <section id="MarriageCourse" className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Course Structure
        </Typography>
        <Grid
          container
          spacing={5}
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={12}>
            <Card className={classes.card}>
              <div className={classes.cardContent}>
                <Typography variant="h6" className={classes.cardTitle}>
                  Preliminary Interview
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                  Before the sessions begin, the couple and the counsellor will
                  meet for a 1-hour Preliminary Interview to discuss
                  expectations for the sessions and to mutually get to know each
                  other.
                </Typography>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <div className={classes.cardContent}>
                <Typography variant="h6" className={classes.cardTitle}>
                  PREPARE Assessment
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                  After the Preliminary Interview, the couple will take an
                  online assessment called PREPARE. This is an evidence-based
                  assessment that provides the couple with quantifiable data
                  that would help the couple understand their relationship
                  better.
                </Typography>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <div className={classes.cardContent}>
                <Typography variant="h6" className={classes.cardTitle}>
                  Couple Sessions
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                  The results of the PREPARE Survey will be discussed between
                  the couple and the counsellor during the sessions.
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Typography
          gutterBottom
          variant="subtitle1"
          className={classes.footnote}
        >
          The sessions are <b>by appointment only</b>. The duration for each
          session is mutually agreed upon with each session typically 1 hour or
          2 hours long.
        </Typography>
      </Container>
    </section>
  );
};

export default withStyles(styles)(MarriageCourse);
