import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import IntroImage from "../../images/marriage-intro-image.jpg";
import QualificationIcon from "../../components/assets/qualification-icon";
import CertificationIcon from "../../components/assets/certification-icon";
import LinkWrapper from "../../components/LinkWrapper";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    marginTop: "35px",
    alignItems: "center",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "1%",
    textAlign: "left",
  },
  profileImage: {
    width: "100%",
    height: "500px",
    position: "relative",
    backgroundImage: `url(${IntroImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "initial",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
      backgroundSize: "contain",
    },
  },
  content: {
    width: "100%",
    paddingTop: "2%",
    paddingBottom: "2%",
    textAlign: "left",
  },
  description: {
    color: "#80818D",
    textAlign: "left",
    paddingBottom: "3%",
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    padding: "10px 40px",
    color: "#4853BBFF",
    border: "1.8px solid #4853BBFF",
    borderRadius: "32px",
    fontWeight: "bold",
    "&:hover": {
      color: "white",
      backgroundColor: "#181A34E6",
      border: "1.5px solid #181A34E6",
    },
  },
}))(Button);

const MarriageIntro = props => {
  const classes = props.classes;
  return (
    <section id="MarriageIntro" className={classes.root}>
      <Container className={classes.container}>
        <Grid
          container
          spacing={5}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <div className={classes.content}>
              <Typography gutterBottom variant="h2" className={classes.title}>
                Marriage Preparation
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                className={classes.description}
              >
                Through discussions and activities, these sessions aim to help
                couples understand their relationship better and be equipped
                with the knowledge and skills needed for their walk together.
              </Typography>
              <LinkWrapper to="/marriage-workshop#MarriageCourse">
                <ColorButton variant="outlined" size="medium">
                  Explore
                </ColorButton>
              </LinkWrapper>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.profileImage}></div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default withStyles(styles)(MarriageIntro);
