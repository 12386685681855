import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import LinkWrapper from "../../components/LinkWrapper";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    marginTop: "35px",
    alignItems: "center",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  container: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "1%",
    textAlign: "center",
  },
  card: {
    margin: "3% auto",
    height: "100%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  description: {
    textAlign: "left",
  },
  listIcon: {
    color: "#4853BB",
    strokeWidth: 1,
    minWidth: "40px",
  },
  list: {
    margin: "0 auto",
    padding: "2% 15%",
  },
  listTitle: {
    fontWeight: "bold",
    color: "#181A34",
    marginBottom: "1%",
    marginTop: "10%",
    textAlign: "center",
  },
  button: {
    marginTop: "5%",
    marginBottom: "10%",
  },
  note: {
    color: "#80818D",
    textAlign: "center",
  },
});

const ColorButton = withStyles(theme => ({
  root: {
    padding: "10px 40px",
    color: "#4853BBFF",
    border: "1.8px solid #4853BBFF",
    borderRadius: "32px",
    fontWeight: "bold",
    "&:hover": {
      color: "white",
      backgroundColor: "#181A34E6",
      border: "1.5px solid #181A34E6",
    },
  },
}))(Button);
const TypographyLink = withStyles(theme => ({
  root: {
    color: "#4853BBFF",
    textAlign: "center",
    "&:hover": {
      color: "#181A34E6",
    },
  },
}))(Typography)

const MarriagePrice = props => {
  const classes = props.classes;
  return (
    <section id="MarriagePrice" className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          PACKAGE INCLUDES
        </Typography>
        <Card className={classes.card}>
          <List className={classes.list}>
            <ListItem>
              <ListItemIcon className={classes.listIcon}>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="1-hour Preliminary Interview"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.listIcon}>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="Online PREPARE Assessment"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.listIcon}>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="PREPARE Couple Report"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.listIcon}>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="5 hours of sessions"
                secondary="(1 hour or 2 hours per session)"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.listIcon}>
                <DoneIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="Paperless Certificate of Completion"
              />
            </ListItem>
            <LinkWrapper to="/marriage-workshop#MarriageRegistration">
              <ColorButton
                className={classes.button}
                variant="outlined"
                size="medium"
                fullWidth
              >
                Book Now
              </ColorButton>
            </LinkWrapper>
          </List>
        </Card>
        <Typography variant="subtitle1" className={classes.note}>
          All payments are <b>non-refundable</b> and must be made prior to the
          sessions.
        </Typography>
        <LinkWrapper to="/marriage-workshop#MarriageOtherEnquiries">
          <TypographyLink variant="subtitle1">
            Enquire for more details
          </TypographyLink>
        </LinkWrapper>
        
      </Container>
    </section>
  );
};

export default withStyles(styles)(MarriagePrice);
