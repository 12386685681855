import React from "react";
import "../styles/index.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import MarriageIntro from "../sections/marriage/marriage-intro";
import MarriageCourse from "../sections/marriage/marriage-course";
import MarriagePrice from "../sections/marriage/marriage-price";
import MarriageRegistration from "../sections/marriage/marriage-registration";
import MarriageTestimonies from "../sections/marriage/marriage-testimonies";
import MarriageOtherEnquiries from "../sections/marriage/marriage-other-enquiries";

const MarriagePage = () => (
  <Layout>
    <SEO title="Cherng's Listening Ear" />
    <MarriageIntro />
    <MarriageCourse />
    <MarriagePrice />
    <MarriageRegistration />
    <MarriageTestimonies />
    <MarriageOtherEnquiries />
  </Layout>
);

export default MarriagePage;
