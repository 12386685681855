export const MarriageWorkshopTestimonies = [
  {
    review:
      "The Marriage Preparation Course conducted by Ming Cherng is a must for all couples who are serious about spending the rest of their lives together.  Not only was it informative, fun and interesting, Ming Cherng’s “bowl” theory helped us understand ourselves a lot more: the why, the how and the what. It is also very helpful that, as a counsellor, Ming Cherng has many real-life examples he shares which definitely made the theories even clearer for us. All clearly broken down visually in diagrams that made all us go, “Oh… that’s why I do this or say that”. With greater self-awareness, we are able to be more sensitive towards our partner’s needs. Thanks to the course, I now look at my partner in a totally different light. She is now not just the woman I’m marrying, but a partner God handpicked for me to help me make my way back to Him! How amazing is that!",
    name: "Raymond Seow",
  },
  {
    review:
      "My wife and I attended Ming Cherng’s Marriage Preparation Course in 2016 and we couldn’t be more grateful for his words of wisdom and guidance. His concepts were simple to grasp and easy to understand. We always looked forward to his sessions as he gives his best to every one of them. His quiet demeanour does not hide the fact that he is a very gentle, caring and kind soul who truly cares for his clients. I continue to meet up with Ming Cherng today to seek his counsel on problems outside of marriage. I am proud to say that he has made a real difference in our lives. Not many have the gift of counselling, and Ming Cherng practises it with extraordinary grace.",
    name: "Julian Low",
  },
  {
    review:
      "We were very blessed to attend the Marriage Preparation Course conducted by Ming Cherng. The course was engaging, well-planned and very helpful. We discussed several basic principles that have helped us understand relationships much better – principles which apply not just to marriage but also our family and friends. We learnt a lot and would encourage other couples to attend too.",
    name: "Amanda Moey",
  },
  {
    review:
      'The Marriage Preparation Course conducted by Ming Cherng was useful and gave me a better idea of what marriage entails. We searched deeper into the Bible to discover more than just why "wives should submit to their husbands" and so on. It also taught me concepts, like the “bowl” in all my relationships with others and God too. Post-course, I feel that my partner and I have become more understanding towards each other with the knowledge gained and discussions we had during the course. She has also told me that I am sweeter and more caring after this course, even though I did not feel I have changed very much. So, I guess it has definitely benefited us. I would recommend this course to anyone who wishes to embark on a serious relationship, not necessarily reserved for those who are engaged only, to discover more about yourself and your partner, bearing in mind that God is the centre of it all.',
    name: "Gerald Sim",
  },
  {
    review:
      "My wife, Clarissa, and I attended Ming Cherng’s Marriage Preparation Course in 2016. The course was comprehensive, covering most, if not, all critical aspects of a new marriage including practical lessons on managing finances, in-laws, and conflicts. Ming Cherng’s counselling sessions were intense in that he asked probing questions to understand the fundamental reasons for behaving the way we do and how that has implications on the relationship. His analogy of the bowl representing the relationship made the sessions easy to understand and appreciate. I recommend Ming Cherng to anyone who is seriously considering ways to improve his or her marriage. His ideas have definitely helped!",
    name: "Melvin Dineshraj",
  },
];
